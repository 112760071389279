.tabs-menu-container {
  @include z-index(header);
  position: sticky;
  top: rem($andes-spacing-64);
}

.tab-menu-carousels .dynamic-carousel:first-child .mshops-recommendations-wrapper {
  margin-top: rem($andes-spacing-24);
}

.tab-menu-carousels .dynamic-carousel:last-child .mshops-recommendations-wrapper {
  padding-bottom: rem(71px) !important;
}

.tab-menu-carousels .dynamic-carousel:not(:first-child) .mshops-recommendations-wrapper {
  margin-top: rem(17px);
}

.remove-shadow-container {
  margin-top: 0;
  position: sticky;
  top: 0;
  @include z-index(eshop-header);

  .ui-ms-profile__header {
    box-shadow: none;
  }
}

.eshops-header--ios {
  .ui-ms-profile__header {
    box-shadow: none;
    position: fixed;
  }
}

.dynamic-carousel {
  .ui-ms-section-eshops {
    margin-bottom: 0;
  }

  .mshops-recommendations-wrapper {
    padding-bottom: 0 !important;
    .ui-ms-section-eshops {
      margin-left: rem($andes-spacing-16);
    }

    .section-eshop__title--layout {
      margin-left: rem(3px);

      .section-header__title--base {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a {
        margin-left: rem($andes-spacing-16);
        white-space: nowrap;
      }
    }
  }

  .ui-eshop-item__card--column.ui-eshop-item__card--not-relevant {
    height: auto;
  }

  .ui-eshop-item__card--not-relevant .ui-eshop-item__content {
    height: auto !important;
  }

  .andes-carousel-snapped__header {
    margin: 0;
  }

  .andes-carousel-snapped--scroll-hidden {
    padding-left: rem(5px);
    padding-top: 0;
  }

  .andes-carousel-snapped__slide {
    height: auto;
  }
}

.eshops-menu-tabs-ios {
  @include z-index(eshop-header);
  box-shadow: 0 rem($andes-spacing-8) rem($andes-spacing-16) 0 $home-gray-100;
  overflow-x: scroll;
  position: fixed;
  width: 100%;
}

.eshops-dynamic-carousel-ios {
  padding-top: rem(112px);
}

.ui-eshop-item__card--not-relevant .ui-eshop-item__content .ui-eshop-item__link .ui-eshop-item__title {
  height: auto;
  line-height: $line-height-s;
  min-height: rem(27px);
}
