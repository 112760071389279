@import 'merch-realestates-web/src/realestates-web/index';
@import '~@andes/card/index';
@import '../skeleton/styles/skeleton';

.ui-ms-merch-slider {
  height: 28vw;
  margin: rem($andes-spacing-20 0 $andes-spacing-20 $andes-spacing-16);
  max-width: 100%;
  width: 96vw;
  overflow: hidden;

  .realestates-web__layout-container {
    width: 90%;
  }

  .realestates-web__banner-single {
    width: 106%;
  }

  .andes-card {
    height: 100%;
  }
}
