@import '../home';
@import './common';

@import '~@mshops-components-library/item/src/styles/mobile';
@import '~@mshops-components-library/common/src/Components/Section/styles/mobile';
@import '~@mshops-components-library/video-and-text/src/styles/general/mobile';
@import '~@mshops-components-library/video-and-text/src/styles/general/mobile';
@import '~@mshops-components-library/video-and-text/src/styles/eshops/mobile';

// Categories Gallery
@import '~@mshops-components-library/categories-gallery/src/styles/base-eshops/mobile';
@import '~@mshops-components-library/categories-gallery/src/CategoryLabelImage/styles/base/mobile';
@import '~@mshops-components-library/category-super/src/styles/mobile';

@import '../../../../components/badge-carousel/styles/mobile';
@import '../../../../components/message/styles/mobile';
@import '../../../../appearance/buyerInfo/styles/mobile';

// Video player
@import '../../../../components/VideoPlayer/styles/mobile';

// Banners Carousel
@import '@mshops-components-library/banners-carousel/src/styles/mobile';

//Repurchase
@import 'eshops-components-library/src/components/Repurchase/lib/mobile';
@import 'eshops-components-library/src/components/Repurchase/lib/bottomsheet';
@import '../../../../appearance/repurchase/styles/mobile.scss';

// Relevant Item Container
@import '@mshops-components-library/relevant-item-container/styles/mobile';

@import '@mshops-components-library/eshop-item/styles/mobile';

@import 'eshops-components-library/src/components/ProfileHeader/lib/mobile';

@import 'eshops-components-library/src/components/EmptyState/lib/mobile';

@import 'eshops-components-library/src/components/AffiliateFeeInfo/lib/mobile';

@import 'eshops-components-library/src/components/SideBar/lib/mobile';

@import '@mshops-components-library/value-prop/styles/index';

//Merch Slider Component
@import '../../../../appearance/merchSlider/styles/mobile';

// Adn Banner
@import '../../../../components/AdnBanner/styles/mobile';

@import '@mshops-components-library/free-shipping-bar/styles/mobile';

@import '../../../../components/dynamicLayout/styles/mobile';
@import 'eshops-components-library/src/components/BottomSheet/index';
@import '../../../../appearance/bottomSheetMenu/styles/index';

//Recommendation Carousel Component
@import '@mshops-components-library/recommendations-carousel/styles/mobile';

.poly-card--grid-card.poly-card--mobile .poly-card__portada--grid-height, .poly-card--grid.poly-card--mobile .poly-card__portada--grid-height {
  height: auto;
}

.ui-ms-polycard-container {
  .poly-component__title {
    color: $andes-gray-900;
  }

  .poly-column__title:first-child {
    color: $andes-gray-900;
  }

  .poly-card--grid-card {
    width: auto;
    max-width: none;
  }

  .poly-component__picture--square {
    object-fit: contain;
  }

  .poly-card--grid-card.poly-card--mobile .poly-card__portada--grid-height, .poly-card--grid.poly-card--mobile .poly-card__portada--grid-height {
    padding: rem($andes-spacing-16 $andes-spacing-16 0);
  }  
}

.home--supermarket {
  background: $andes-white;
  background-color: $andes-white;
}

.home--supermarket .section-category {
  box-sizing: border-box;
  justify-content: center;
  margin: 0 rem($andes-spacing-16) rem($andes-spacing-24);
  padding: 0;

  .section-category__title--layout {
    margin-bottom: rem($andes-spacing-12);
    min-width: auto;
  }
}

.home--supermarket {
  .section-category {
    .section-category__container {
      padding: 0;
      margin: 0;
    }

    .category-super-item__container {
      gap: rem($andes-spacing-8);
      padding-top: 0;
    }

    .category-super-item__title {
      min-height: rem(30px);
    }

    .section-category__columns {
      justify-content: center;
    }
  }

  .row.container.section-category__container .section-category__title {
    margin-bottom: 0;
  }
}

.ui-relevant-item {
  .ui-eshop-item__image_container {
    .ui-eshop-item__image {
      max-height: 14.5rem;
    }
  }
}

main:has(.home--supermarket) {
  & + footer {
    display: none;

    & + div .nav-new-cookie-disclaimer {
      z-index: 1200;
    }
  }
}

.home--supermarket {
  .section-text > .section-text__container {
    height: rem(140px);
    .section-text__row {
      padding: 0 rem(16px);
      margin-top: rem(29px);
      margin-bottom: rem(80px);
    }

    .section-text__text {
      font-family: 'Proxima Nova';
      font-size: $font-size-12;
      font-style: normal;
      font-weight: $font-weight-regular;
      line-height: rem(15px);
    }
  }

  .mshops-recommendations-wrapper {
    padding-bottom: 0 !important;

    .ui-ms-section-eshops {
      margin-bottom: 0;
    }
  }
}

.home--supermarket .andes-progress-indicator-circular--fullscreen {
  background-color: #eee;

  .andes-progress-indicator-circular__progress {
    stroke-width: 2px;
    height: rem(84px);
    width: rem(84px);
  }
}

.affiliate-container {
  padding: $andes-spacing-32 $andes-spacing-16;
}

.mshops-recommendations-wrapper.ui-ms-polycard-carousel .andes-carousel-free {
  margin-right: rem(-$andes-spacing-16);
  margin-left: rem(-$andes-spacing-16);
  padding-left: rem(1px);

  .andes-carousel-free__slide:first-child {
    margin-left: rem($andes-spacing-16);
  }
}

.ui-ms-products-gallery__polycard .ui-ms-products-gallery__container {
  .andes-card--flat {
    box-shadow: 0 rem(6px) rem($andes-spacing-16) 0 rgba(0, 0, 0, 0.1);
  }

  li {
    width: 50%;
  }
}

.ui-ms-products-gallery__polycard--editable .ui-eshop-item__card--seller {
  flex: auto;
  width: 50%;
}

.mshops-recommendations-wrapper.ui-ms-polycard-carousel {
  padding-bottom: 0 !important;

  .poly-card--grid, .poly-card--grid-card {
    --poly-min-width: 160px;
  }
}

.hub__boxed-width .content .banner-text {
  margin: 0 !important;
}

.ui-ms-free-shipping-bar {
  bottom: 0;
  position: fixed;
  z-index: 999;
}
