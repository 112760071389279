.ui-bs-menu-layout__float {
  position: absolute;
  right: rem(-430px);
  top: 0;
  z-index: 1100;

  &.ui-bs-menu-layout__float-show {
    background-color: $andes-white;
    height: 100%;
    right: 0;
    width: 100%;

    &.ui-bs-menu-layout__float--full {
      height: 100%;
      overflow: auto;
      overflow-wrap: break-word;

      .ui-ms-bottom-sheet-content__card {
        justify-content: flex-start;
    
        .ui-ms-bottom-sheet-content__link {
          width: rem(80px);
        }
      }
    }
  }

  &:not(.ui-bs-menu-layout__float-show) .ui-bs-menu {
    box-shadow: none;
  }
}

@media (min-width: 375px) {
  .ui-bs-menu-layout__float.ui-bs-menu-layout__float-show.ui-bs-menu-layout__float--full.ui-bs-menu-eshops--ios {
    padding-top: rem($andes-spacing-56);
    height: calc(100vh - rem($andes-spacing-56));
  }
}
