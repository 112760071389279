@import '../eshops/mobile-eshops';
// Relevant Grid Container
@import '@mshops-components-library/relevant-grid-container/styles/mobile';

.ui-recommended-products {
  margin: rem($andes-spacing-24) 0 0 0;
}

.ui-ms-polycard-container-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  justify-content: flex-start;

  .poly-card--grid, .poly-card--grid-card {
    --poly-min-width: calc(50% - 1px);
    --poly-max-width: calc(50% - 1px);
    --poly-general-image-divider: unset;
  }
}

.ui-ms-polycard-container {
  .poly-card--grid-card.poly-card--mobile .poly-card__portada--grid-height, .poly-card--grid.poly-card--mobile .poly-card__portada--grid-height {
    height: auto;
  }

  .poly-card__portada {
    aspect-ratio: inherit;
  }  
}
